<script>
  /* eslint-disable */
  import NavMenu from './components/NavMenu.vue'
  import Footer from './components/Footer.vue'

  export default {
    name: 'App',
    components: {
      NavMenu,
      Footer
    }
  }
</script>

<template>
  <div class="contentContainer">
    <nav-menu></nav-menu>
    <router-view />
  </div>
  <Footer />
</template>



<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  
}
	.contentContainer {
		min-height: calc(100vh - 70px);
	}
	footer {
		height: 69px;
		background: #FCE7AE;
		color: #444;
		text-align: left;
	}

		footer .footerContent{
      max-width:1100px;
      margin:0 auto;
      font-size:12px;
		}
</style>
