<template>
	<footer>
		<div class="footerContent">
			<div><a href="https://vbr.brackenlearning.com/REST/zoneskinassetmanager/HOME/xxx/TermsAndPrivacy/TermsAndPrivacy.html?v=2711061" id="termsAndPrivacyLink" target="_blank">Terms &amp; Privacy</a></div>
			<div>Version 2.1.0</div>
		</div>
	</footer>
</template>
<style>
	.footerContent div{
		display:inline-block;
		margin:5px 10px;
	}
	a {
		color:#444;
	}
</style>
<script>
  /* eslint-disable */
	export default {
		name: "Footer"
	}
</script>