<template>
	<header>
		<nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border-bottom box-shadow mb-3">
			<div class="container">
				<a class="navbar-brand"><img src="https://vbr.brackenlearning.com/rest2/media/skinasset/LoginPageOverimage/2711060" /></a>
				<button class="navbar-toggler"
								type="button"
								data-toggle="collapse"
								data-target=".navbar-collapse"
								aria-label="Toggle navigation"
								@click="toggle">
					<span class="navbar-toggler-icon"></span>
				</button>
				<div class="navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse" v-bind:class="{show: isExpanded}">
					<ul class="navbar-nav flex-grow">
						<!-- 
						<li class="nav-item">
							<router-link :to="{ name: 'Home' }" class="nav-link text-dark">Home</router-link>
						</li>
							-->
						<!--
						<li class="nav-item">
							<router-link :to="{ name: 'Counter' }" class="nav-link text-dark">Counter</router-link>
						</li>
						<li class="nav-item">
							<router-link :to="{ name: 'FetchData' }" class="nav-link text-dark">Fetch Data</router-link>
						</li> -->
						<li class="nav-item">
							<router-link :to="{ name: 'Login' }" class="nav-link text-dark">{{ logintext }}</router-link>
						</li>
						<!--
						<li v-show="haveUser" class="nav-item">
							<router-link :to="{ name: 'Interview' }" class="nav-link text-dark">Interview</router-link>
						</li> -->
					</ul>
				</div>
			</div>
		</nav>
	</header>
</template>
<style>
	a.navbar-brand {
		white-space: normal;
		text-align: center;
		word-break: break-all;
	}
	html {
		font-size: 14px;
	}
	@media (min-width: 768px) {
		html {
			font-size: 16px;
		}
	}
	.box-shadow {
		box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
	}
	nav.navbar {
		background-image: url(https://vbr.brackenlearning.com/rest2/media/skinasset/LiveOverhead/v2);
		background-position-x: left;
		height: 91px;
	}
	.nav-item{
		margin-top:10px;
	}
</style>
<script>
	export default {
		name: "NavMenu",
		data() {
			return {
				isExpanded: false,
				logintext: 'Login.'
			}
		},
		watch: {
			$route() {
				// clear alert on location change
				//this.clearAlert();
				this.logintext = localStorage.getItem('user') === null ? 'Login' : 'Logout';
				//this.haveUser = !localStorage.getItem('user') === null;
			}
		},
		computed: {
			haveUser: function () {
				return this.logintext === "Logout";
			}
		},
		methods: {
			collapse() {
				this.isExpanded = false;
			},
			toggle() {
				this.isExpanded = !this.isExpanded;
			}
		}
	}
</script>
