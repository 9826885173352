import { createWebHistory, createRouter } from "vue-router";
import Home from "@/components/Home.vue";
import LoginView from "@/components/LoginView.vue";
import Interview from "@/components/Interview.vue";

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{ path: '/login', name: 'Login', component: LoginView },	
	{ path: '/interview', name: 'Interview', component: Interview },	
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	// redirect to login page if not logged in and trying to access a restricted page
	const publicPages = ['/', '/login'];
	const authRequired = !publicPages.includes(to.path);
	const loggedIn = localStorage.getItem('user');

	if (authRequired && !loggedIn) {
		return next('/login');
	}

	next();
})

export default router;