<template>
	<div class="userInfo" v-if="user !== null">Logged in as:{{user.userdata.details.friendlyName}}</div>
	<div class="recorder">
		<h1>Video recorder</h1>
		
		<div class="settingsBar"><div class="" @click="onOpenSettings"><i class="fa fa-cog" style="font-size:36px;"></i></div></div>
		<div v-show="showSettings" class="settingsPanel">
			<div>
				<label for="videoSource">Video source:</label>
				<select id="videoSource" @change="start"></select>
			</div>
			<div>
				<label for="audioSource">Audio source:</label>
				<select id="audioSource"></select>
			</div>
			<div>
				<label for="audioOutput">Audio output:</label>
				<select id="audioOutput"></select>
			</div>
			<div v-if="videoTrackSettings != null">Connected result: Framerate: {{ videoTrackSettings.frameRate
			}} Width: {{ videoTrackSettings.width }} Height: {{ videoTrackSettings.height }} Codec: {{ mimeType }}
		</div>
			<div>
				<button class="btn btn-primary" id="connect" @click="onConnect" :disabled="isRecording">{{ buttonText	}}</button>
			</div>
		</div>

		<div class="videoContainer">
			<video id="preview" autoplay playsinline controls="controls" muted v-bind:class="{ recording: isRecording }"></video>
			<div v-show="isRecording" class="recodingIcon"></div>
		</div>
		<div>
			
			<button class="btn btn-danger" id="record" :disabled="isRecording" @click="onRecord">Record</button>
			<button class="btn btn-primary" id="stop" :disabled="!isRecording" @click="onStopRecord">Stop</button>
		</div>
		
		<div>Record time: <strong> {{ formatedRecordTime }} </strong> Max time allowed: {{ formatedMaxTime }}</div>
		<div v-show="uploadedValue > 0">
			<label for="fileupload">Upload progress:</label>
			<progress id="fileupload" :value="uploadedValue" max="100"></progress>
		</div>
		<div>{{ statusMsg }}</div>
	</div>
</template>
<script>
	/* eslint-disable */

// import {adapter} from 'webrtc-adapter';
// import axios from "axios";

	const adapter = require('webrtc-adapter');
	const axios = require('axios');
	const { BlobServiceClient, BlockBlobClient, BlobClient } = require("@azure/storage-blob");

export default {
	name: "Interview",
	data(){
		return{
			isRecording: false,
			isUploading: false,
			isConnected: false,
			stream: null,
			showSettings: false,
			//recordAudio: null,
			recordVideo: null,
			videoElement: null,
			uploadedValue: 0,
			videoTrackSettings: null,
			recordTimer: null,
			timerData: 0,
			maxRecordSeconds: 30 * 60,
			mimeType:"",
			mediaRecorder:null,
			recordedBlobs: null,
			statusMsg: "",
			user: null,
			filename:null
		}
	},
	computed: {
		buttonText: function () {
			return this.isConnected ? "Re-connect" : "Connect";
		},
		formatedRecordTime: function () {
			var duration = this.timerData;
			return this.formatTime(duration);
		},
		formatedMaxTime: function () {
			return this.formatTime(this.maxRecordSeconds);
		}
	},
	methods: {
		onConnect(){
			this.start();
			//JSON.parse(localStorage.getItem('user')).userdata.details.friendlyName
			this.user = JSON.parse(localStorage.getItem('user'));
		},
		onRecord(){
			if(this.isRecording){
				this.stopRecording();
				this.isRecording = false;
			}else{
				//this.isRecording = true;
				this.startRecording();
				this.uploadedValue = -1;
			}
		},
		startRecording(){
			this.recordedBlobs = [];
			let options = {mimeType: 'video/webm;codecs=vp9,opus'};
			if (!MediaRecorder.isTypeSupported(options.mimeType)) {
				console.error(`${options.mimeType} is not supported`);
				options = {mimeType: 'video/webm;codecs=vp8,opus'};
				if (!MediaRecorder.isTypeSupported(options.mimeType)) {
					console.error(`${options.mimeType} is not supported`);
					options = {mimeType: 'video/webm'};
					if (!MediaRecorder.isTypeSupported(options.mimeType)) {
						console.error(`${options.mimeType} is not supported`);
						options = {mimeType: ''};
					}
				}
			}
			try {
				this.mimeType = options.mimeType;
				this.mediaRecorder = new MediaRecorder(window.stream, options);
			} catch (e) {
				console.error('Exception while creating MediaRecorder:', e);
				// errorMsgElement.innerHTML = `Exception while creating MediaRecorder: ${JSON.stringify(e)}`;
				return;
			}

			console.log('Created MediaRecorder', this.mediaRecorder, 'with options', options);
			this.isRecording = true;
			this.mediaRecorder.onstop = (event) => {
				console.log('Recorder stopped: ', event);
				console.log('Recorded Blobs: ', this.recordedBlobs);
			};
			this.mediaRecorder.ondataavailable = this.handleDataAvailable;
			this.mediaRecorder.start();
			this.startTimer();
			console.log('MediaRecorder started', this.mediaRecorder);
		},
		handleDataAvailable(event) {
			console.log('handleDataAvailable', event);
			if (event.data && event.data.size > 0) {
				this.recordedBlobs.push(event.data);
				//this.onUploadAzure();
				this.getSasToken();
			}
		},
		stopRecording() {
			this.mediaRecorder.stop();
		},
		onStopRecord(){
			this.stopRecording();
			this.isRecording = false;
			this.stopTimer();
		},
		onOpenSettings() {
			this.showSettings = !this.showSettings;
		},
		formatTime(duration) {
			//var duration = this.timerData;
			// Hours, minutes and seconds
			var hrs = ~~(duration / 3600);
			var mins = ~~((duration % 3600) / 60);
			var secs = ~~duration % 60;
			// ~~ short cut to Math.floor()
			// Output like "1:01" or "4:03:59" or "123:03:59"
			var ret = "";

			if (hrs > 0) {
				ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
			}

			ret += "" + mins + ":" + (secs < 10 ? "0" : "");
			ret += "" + secs;
			return ret;
		},
		getSasToken() {
      let user = JSON.parse(localStorage.getItem('user'));
      let rnd = Date.now().toString();
			this.filename = `${user.userdata.details.id}-${this.videoTrackSettings.width}x${this.videoTrackSettings.height}-${this.videoTrackSettings.frameRate}-${rnd}.webm`;
			// Get blob access sas
			axios.request({
				method: "get",
				url: `/api/media/sas/${this.filename}`
			}).then(reponse => { // eslint-disable-line no-unused-vars				
        this.onUploadAzure(reponse.data, user.userdata.details.id);
			}).catch(function (error) { // eslint-disable-line no-unused-vars
				// handle error
				this.statusMsg = "Error getting sas " + error;
			})
		},
		// Upload function
		async onUploadAzure(blobSasUrl, userId) {
			try {
				this.statusMsg = "Uploading....";
				const blobData = new Blob(this.recordedBlobs, { type: 'video/webm' });
				this.isRecording = false;
				this.uploadedValue = 1;				
				const fileSize = blobData.size;

				const promises = [];				
				const uploadToBlockBlobOptions = {};

				// Upload progress handler
				uploadToBlockBlobOptions.onProgress = (ev) => {					
					this.uploadedValue = (ev.loadedBytes / fileSize) * 100;
				}
				uploadToBlockBlobOptions.blobHTTPHeaders = { blobContentType: "video/webm" };
				
        const blobClient = new BlobClient(blobSasUrl);
				const blockBlobClient = blobClient.getBlockBlobClient();

				promises.push(blockBlobClient.uploadData(blobData, uploadToBlockBlobOptions));

				await Promise.all(promises);
				this.statusMsg = "Upload complete.... " + this.filename;
				this.queueEncoder(this.filename, userId);
			} catch(error) {
				this.statusMsg = error.message;
			}

		},
		queueEncoder(filePath, userId) {
			// let url = "https://interviewappfuctions.azurewebsites.net/api/StartEncode?code=O6wUvd5zBAk9WLGUvlbST8IW3X06acJO6czMOfBLEHuQXmJgZOgjRg==";
			let url = "https://awsencoderapp.azurewebsites.net/api/CreateJob?code=CGduTbkehG_Vc8uFTBdBQMIIPsg2YoGZuYui_6CjsjhLAzFu13K5mA==";
			//let data = {
			//	"SourceName": filename,
			//	"OutputAssetNamePrefix": rnd
			//};
			let data = {
				"UserId": userId,
        "FileName": `VBR/${filePath}`
			};
			this.statusMsg = "Queueing video for processing";
			axios.request({
				method: "post",
				url: url,
				data: data
			}).then(data => { // eslint-disable-line no-unused-vars
				console.log("QJob result" + data);
				this.statusMsg = "Complete. You may now log out.";
			}).catch((error) => { // eslint-disable-line no-unused-vars
				// handle error
				console.error(error)
        this.statusMsg = "Error queuing video " + filePath;
			})
		},
		startTimer() {
			this.timerData = 0;
			this.recordTimer = setInterval(() => {
				this.timerData++;
				if (this.timerData > (this.maxRecordSeconds)) {
					this.onStopRecord();
				}
			}, 1000);
		},
		stopTimer() {
			clearInterval(this.recordTimer);
		},
		gotDevices(deviceInfos) { 
			// Handles being called several times to update labels. Preserve values.
			const audioInputSelect = document.querySelector('select#audioSource');
			const audioOutputSelect = document.querySelector('select#audioOutput');
			const videoSelect = document.querySelector('select#videoSource');
			const selectors = [audioInputSelect, audioOutputSelect, videoSelect];
			const values = selectors.map(select => select.value);
			selectors.forEach(select => {
				while (select.firstChild) {
					select.removeChild(select.firstChild);
				}
			});
			for (let i = 0; i !== deviceInfos.length; ++i) {
				const deviceInfo = deviceInfos[i];
				const option = document.createElement('option');
				option.value = deviceInfo.deviceId;
				if (deviceInfo.kind === 'audioinput') {
					option.text = deviceInfo.label || `microphone ${audioInputSelect.length + 1}`;
					audioInputSelect.appendChild(option);
				} else if (deviceInfo.kind === 'audiooutput') {
					option.text = deviceInfo.label || `speaker ${audioOutputSelect.length + 1}`;
					audioOutputSelect.appendChild(option);
				} else if (deviceInfo.kind === 'videoinput') {
					option.text = deviceInfo.label || `camera ${videoSelect.length + 1}`;
					videoSelect.appendChild(option);
				} else {
					console.log('Some other kind of source/device: ', deviceInfo);
				}
			}
			selectors.forEach((select, selectorIndex) => {
				if (Array.prototype.slice.call(select.childNodes).some(n => n.value === values[selectorIndex])) {
					select.value = values[selectorIndex];
				}
			});
		},
		gotStream(stream) {
			window.stream = stream; // make stream available to console
			this.videoElement = document.getElementById('preview');
			this.videoElement.srcObject = stream;
			this.videoTrackSettings = stream.getVideoTracks()[0].getSettings();			
			this.isConnected = true;
			return navigator.mediaDevices.enumerateDevices();
		},
		handleError(error) {
			console.error('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
		},
		start() {
			if (window.stream) {
				window.stream.getTracks().forEach(track => {
					track.stop();
				});
			}
			const audioSource = document.getElementById("audioSource").value;
			const videoSource = document.getElementById("videoSource").value;
			const videoDeviceId =  videoSource ? {exact: videoSource} : undefined;
			const constraints = {
				audio: {deviceId: audioSource ? {exact: audioSource} : undefined},
				video: {deviceId: videoDeviceId, width: 1280, height: 720, frameRate: { min: 15, max: 30 }}
			};
			navigator.mediaDevices.getUserMedia(constraints)
			.then(this.gotStream)
			.then(this.gotDevices)
			.catch(this.handleError);
		} //start
		
		},
		stop() {
			if (window.stream) {
				window.stream.getTracks().forEach(track => {
					track.stop();
				});
			}
		},
		mounted() {
			this.user = JSON.parse(localStorage.getItem('user'));
			if(adapter){
				//console.log(adapter);
				adapter.default.disableLog(true);
			}
			navigator.mediaDevices.enumerateDevices().then(this.gotDevices).catch(this.handleError);
			this.start();
		},
		beforeUnmount() {
			if (window.stream) {
				window.stream.getTracks().forEach(track => {
					track.stop();
				});
			}
		},
		beforeRouteLeave(to, from, next) { // eslint-disable-line no-unused-vars
			// called when the route that renders this component is about to
			// be navigated away from.
			// has access to `this` component instance.
			if (this.isRecording) {
				this.onStopRecord();
			} else {
				next();
			}
		}
}
</script>
<style scoped>
	video {
		width: 640px;
		height: 360px;
	}

	.videoContainer {
		position: relative;
		width: 640px;
		margin: 0 auto
	}

	.recodingIcon {
		position: absolute;
		width: 20px;
		height: 20px;
		border-radius: 10px;
		background: red;
		top: 10px;
		left: 10px;
	}

	.settingsPanel {
		width: 640px;
		margin: 0 auto;
		text-align: left;
		border: 1px #444 solid;
		padding: 10px 2px;
	}

		.settingsPanel label {
			width: 19%;
		}

		.settingsPanel select {
			max-width: 80%;
		}

	.settingsBar {
		width: 640px;
		margin: 0 auto;
		text-align: right;
	}

	button {
		margin: 5px;
	}

	.userInfo {
		position: relative;
		top: -67px;
		max-width: 640px;
		margin: 0 auto;
	}
</style>
