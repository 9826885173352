<template>
	<div class="loginform">
		<h2>Login</h2>
		<div class="loginError" v-show="loginError">User name or password incorrect</div>
		<form @submit.prevent="handleSubmit">
			<div class="form-group">
				<label for="username">Username</label>
				<input type="text" v-model="username" name="username" class="form-control" :class="{ 'is-invalid': submitted && !username }" />
				<div v-show="submitted && !username" class="invalid-feedback">Username is required</div>
			</div>
			<div class="form-group">
				<label htmlFor="password">Password</label>
				<input type="password" v-model="password" name="password" class="form-control" :class="{ 'is-invalid': submitted && !password }" />
				<div v-show="submitted && !password" class="invalid-feedback">Password is required</div>
			</div>
			<div class="form-group button-bar">
				<button class="btn btn-primary" :disabled="loggingIn">Login</button>
				<router-link :to="{ name: 'Home' }" class="btn btn-secondary">Cancel</router-link>
				<img v-show="loggingIn" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />

			</div>
		</form>
	</div>

</template>

<script>  
	import router from '../router/index';
	const axios = require('axios');
	export default {
		data() {
			return {
				username: '',
				password: '',
				submitted: false,
				loggingIn: false,
				loginError: false
			}
		},
		created() {
			// reset login status
			this.logout();
		},
		methods: {
			logout() {
				localStorage.removeItem('user');
			},
			handleSubmit() {
				this.submitted = true;
				this.loggingIn = true;
				const { username, password } = this;
				if (username && password) {
					//this.login({ username, password })
					//console.log(username);
					/*
					if (password === 'popup') {
						var user = { username: username, skin: 1000, token: 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx' };
						localStorage.setItem('user', JSON.stringify(user));
						router.push('/interview');
					} else {
						this.loggingIn = false;
						this.loginError = true;
					}
					*/
					axios.request({
						method: "post",
						url: "/api/auth",
						data: { username: username, password: password, product: "SCUploaderWindows_SC" }
					}).then(data => {
						// console.log(data.data);
						if (data.data.success) {
							var user = { username: username, userdata:data.data };
							localStorage.setItem('user', JSON.stringify(user));
							router.push('/interview')
						} else {
							this.loggingIn = false;
							this.loginError = true;
						}
					});
				}
			}
		}
	}
</script>

<style scoped>
	.loginform {
		width: 400px;
		margin: 0 auto;
		background-color: lightgrey;
		border: 1px white solid;
		border-radius: 10px;
		padding: 10px 50px;
		position: relative;
		top: 50px;
	}
	.loginError{
		color: red;
	}
	.form-group button{
		margin-right:10px;
	}
	.form-group a{
		margin-left:10px;
	}
  .button-bar {
		margin-top:10px;
  }
</style>