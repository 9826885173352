<template>
	<div class="hello">
		<h1>Video Based Reflection for Clinical Practice</h1>
		<div><img alt="Vue logo" src="../assets/VBR_Home_notext.jpg" /></div>
		<div>
			<router-link :to="{ name: 'Login' }" class="btn btn-secondary">{{ logintext }}</router-link>
		</div>
	</div>
</template>

<script>
  /* eslint-disable */
export default {
  name: 'Home',
  props: {
    msg: String
    },
    data() {
      return {logintext:'Login'}
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}

</style>
